import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { Box, Typography, Button, CircularProgress } from '@mui/material';

const Header = ({ title }) => {
  const { user, setUser, loading } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('phone');
    localStorage.removeItem('first_name');
    localStorage.removeItem('last_name');
    localStorage.removeItem('company');
    setUser(null);
    navigate('/');
  };

  if (loading) {
    return (
      <Box
        sx={{
          p: 2,
          bgcolor: 'primary.main',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <CircularProgress size={24} sx={{ color: 'white' }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: 'primary.main',
        color: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {user ? (
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="body1">
              {user.first_name || 'Нет имени'} {user.last_name || 'Нет фамилии'}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.8 }}>
              {user.company || 'Нет компании'}
            </Typography>
          </Box>
        ) : (
          <Typography variant="body1">Гость</Typography>
        )}
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Выйти
        </Button>
      </Box>
    </Box>
  );
};

export default Header;