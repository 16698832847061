// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './context/UserContext';
import Login from './components/Login';
import Users from './components/Users';
import ManagerAccess from './components/ManagerAccess';
import Dashboard from './components/Dashboard';
import InspectionCards from './components/InspectionCards';
import Header from './components/Header'; // Убедитесь, что Header импортируется, если он используется здесь

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/inspection-cards" element={<InspectionCards />} />
          <Route path="/users" element={<Users />} />
          <Route path="/manager-access" element={<ManagerAccess />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;