// src/components/Users.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Header from './Header';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TextField,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  LinearProgress,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.95rem',
  padding: '12px 16px',
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#E8F0FE',
  },
}));

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPassword, setNewPassword] = useState({});
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [openAddVehicleDialog, setOpenAddVehicleDialog] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [newUser, setNewUser] = useState({
    phone: '',
    password: '',
    first_name: '',
    last_name: '',
  });
  const [newVehicle, setNewVehicle] = useState({
    license_plate: '',
  });
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [importProgress, setImportProgress] = useState(0);
  const [importTotal, setImportTotal] = useState(0);
  const [importCompleted, setImportCompleted] = useState(false);
  const [importing, setImporting] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editedFirstName, setEditedFirstName] = useState('');
  const [editedLastName, setEditedLastName] = useState('');
  const [editedCompany, setEditedCompany] = useState('');

  const navigate = useNavigate();
  const role = localStorage.getItem('role');
  const token = localStorage.getItem('token'); // Исправлено: token须 → token

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!token) {
          console.error('No token found in localStorage');
          navigate('/');
          return;
        }

        const response = await axios.get('http://77.221.151.92/api/all-users/', {
          headers: { Authorization: `Token ${token}` },
        });
        setUsers(response.data);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Ошибка при загрузке пользователей');
        if (err.response && err.response.status === 401) {
          navigate('/');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate, token]);

  const handlePasswordChange = async (userId) => {
    if (!newPassword[userId]) {
      setError('Введите новый пароль');
      return;
    }

    try {
      await axios.post(
        'http://77.221.151.92/api/change-user-password/',
        { user_id: userId, new_password: newPassword[userId] },
        { headers: { Authorization: `Token ${token}` } }
      );
      setError(null);
      alert('Пароль успешно изменён');
    } catch (err) {
      setError('Ошибка при смене пароля');
      console.error('Error changing password:', err);
    }
  };

  const handleOpenAddUserDialog = () => {
    setOpenAddUserDialog(true);
  };

  const handleCloseAddUserDialog = () => {
    setOpenAddUserDialog(false);
    setNewUser({
      phone: '',
      password: '',
      first_name: '',
      last_name: '',
    });
  };

  const handleAddUser = async () => {
    try {
      const response = await axios.post('http://77.221.151.92/api/add-user/', newUser, {
        headers: { Authorization: `Token ${token}` },
      });
      setUsers([...users, response.data]);
      handleCloseAddUserDialog();
      alert('Клиент успешно добавлен');
    } catch (error) {
      console.error('Error adding user:', error);
      if (error.response) {
        if (error.response.status === 400) {
          const errors = error.response.data;
          let errorMessage = 'Ошибка при добавлении клиента: ';
          if (typeof errors === 'string') {
            errorMessage += errors;
          } else if (errors.phone) {
            errorMessage += `Телефон: ${errors.phone.join(', ')}. `;
          } else if (errors.detail) {
            errorMessage += errors.detail;
          } else {
            errorMessage += JSON.stringify(errors);
          }
          setError(errorMessage);
        } else if (error.response.status === 500) {
          setError('Внутренняя ошибка сервера. Пожалуйста, попробуйте позже.');
        } else {
          setError(`Ошибка: ${error.response.status} - ${error.response.statusText}`);
        }
      } else {
        setError('Ошибка при добавлении клиента: нет ответа от сервера');
      }
    }
  };

  const handleOpenAddVehicleDialog = (clientId) => {
    setSelectedClientId(clientId);
    setOpenAddVehicleDialog(true);
  };

  const handleCloseAddVehicleDialog = () => {
    setOpenAddVehicleDialog(false);
    setSelectedClientId(null);
    setNewVehicle({
      license_plate: '',
    });
  };

  const handleAddVehicle = async () => {
    try {
      await axios.post('http://77.221.151.92/api/add-vehicle-to-client/', {
        client_id: selectedClientId,
        license_plate: newVehicle.license_plate,
      }, {
        headers: { Authorization: `Token ${token}` },
      });

      handleCloseAddVehicleDialog();
      alert('Машина успешно добавлена');
    } catch (error) {
      console.error('Error adding vehicle:', error);
      if (error.response) {
        if (error.response.status === 400) {
          const errors = error.response.data;
          let errorMessage = 'Ошибка при добавлении машины: ';
          if (typeof errors === 'string') {
            errorMessage += errors;
          } else if (errors.error) {
            errorMessage += errors.error;
          } else {
            errorMessage += JSON.stringify(errors);
          }
          setError(errorMessage);
        } else if (error.response.status === 500) {
          setError('Внутренняя ошибка сервера. Пожалуйста, попробуйте позже.');
        } else {
          setError(`Ошибка: ${error.response.status} - ${error.response.statusText}`);
        }
      } else {
        setError('Ошибка при добавлении машины: нет ответа от сервера');
      }
    }
  };

  const handleOpenImportDialog = (clientId) => {
    setSelectedClientId(clientId);
    setOpenImportDialog(true);
    setImportFile(null);
    setImportProgress(0);
    setImportTotal(0);
    setImportCompleted(false);
    setImporting(false);
  };

  const handleCloseImportDialog = () => {
    setOpenImportDialog(false);
    setSelectedClientId(null);
    setImportFile(null);
    setImportProgress(0);
    setImportTotal(0);
    setImportCompleted(false);
    setImporting(false);
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setImportFile(e.target.files[0]);
    }
  };

  const handleImportVehicles = async () => {
    if (!importFile) {
      setError('Выберите файл для импорта');
      return;
    }

    setImporting(true);
    setError(null);

    const reader = new FileReader();
    reader.onload = async (evt) => {
      try {
        const bstr = evt.target.result;
        const workbook = XLSX.read(bstr, { type: 'binary' });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });
        const licensePlates = jsonData.map(row => row["Госномер"]).filter(lp => lp);

        if (licensePlates.length === 0) {
          setError('В файле не найден столбец "Госномер" или он пустой');
          setImporting(false);
          return;
        }

        setImportTotal(licensePlates.length);
        for (let i = 0; i < licensePlates.length; i++) {
          try {
            await axios.post('http://77.221.151.92/api/add-vehicle-to-client/', {
              client_id: selectedClientId,
              license_plate: licensePlates[i],
            }, {
              headers: { Authorization: `Token ${token}` },
            });
          } catch (err) {
            console.error(`Ошибка при добавлении машины с госномером ${licensePlates[i]}:`, err);
          }
          setImportProgress(((i + 1) / licensePlates.length) * 100);
          await new Promise(resolve => setTimeout(resolve, 5000));
        }

        setImportCompleted(true);
        setImporting(false);
        alert('Импорт автомобилей завершён');
      } catch (error) {
        console.error('Ошибка при импорте файла:', error);
        setError('Ошибка при импорте файла');
        setImporting(false);
      }
    };
    reader.readAsBinaryString(importFile);
  };

  const handleOpenEditDialog = (user) => {
    setSelectedUser(user);
    setEditedFirstName(user.first_name || '');
    setEditedLastName(user.last_name || '');
    setEditedCompany(user.company || '');
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedUser(null);
    setEditedFirstName('');
    setEditedLastName('');
    setEditedCompany('');
  };

  const handleEditUser = async () => {
    try {
      const response = await axios.post(
        'http://77.221.151.92/api/change-user-details/',
        {
          user_id: selectedUser.id,
          first_name: editedFirstName,
          last_name: editedLastName,
          company: editedCompany,
        },
        { headers: { Authorization: `Token ${token}` } }
      );
      setUsers(users.map(u => (u.id === selectedUser.id ? response.data.user : u)));
      handleCloseEditDialog();
      alert('Данные пользователя обновлены');
    } catch (err) {
      setError('Ошибка при обновлении данных пользователя');
      console.error('Error editing user:', err);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f5f5f5' }}>
      <Header title="Все пользователи" />
      <Box sx={{ p: 3, maxWidth: '1400px', margin: '0 auto' }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {['superadmin', 'owner', 'programmer'].includes(role) && (
          <Box sx={{ mb: 3 }}>
            <Button variant="contained" color="primary" onClick={handleOpenAddUserDialog} sx={{ mr: 2 }}>
              Создать пользователя
            </Button>
          </Box>
        )}
        <TableContainer component={Paper} sx={{ borderRadius: '8px', boxShadow: '0 4px 12px rgba(0,0,0,0.1)' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ bgcolor: '#E8F0FE' }}>
                <StyledTableCell>Телефон</StyledTableCell>
                <StyledTableCell>Имя</StyledTableCell>
                <StyledTableCell>Фамилия</StyledTableCell>
                <StyledTableCell>Компания</StyledTableCell>
                <StyledTableCell>Роль</StyledTableCell>
                <StyledTableCell>Новый пароль</StyledTableCell>
                <StyledTableCell>Действия</StyledTableCell>
                <StyledTableCell>Добавить машину</StyledTableCell>
                <StyledTableCell>Импорт машин</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <StyledTableRow key={user.id}>
                  <StyledTableCell>{user.phone}</StyledTableCell>
                  <StyledTableCell>{user.first_name || '-'}</StyledTableCell>
                  <StyledTableCell>{user.last_name || '-'}</StyledTableCell>
                  <StyledTableCell>{user.company || '-'}</StyledTableCell>
                  <StyledTableCell>{user.role || '-'}</StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      size="small"
                      type="password"
                      value={newPassword[user.id] || ''}
                      onChange={(e) => setNewPassword({ ...newPassword, [user.id]: e.target.value })}
                      sx={{ width: '120px' }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handlePasswordChange(user.id)}
                      sx={{ mr: 1, bgcolor: '#388e3c', '&:hover': { bgcolor: '#2e7d32' } }}
                    >
                      Сменить пароль
                    </Button>
                    {['superadmin', 'owner', 'programmer'].includes(role) && (
                      <IconButton
                        onClick={() => handleOpenEditDialog(user)}
                        sx={{ color: '#1976d2' }}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {['superadmin', 'owner', 'programmer'].includes(role) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenAddVehicleDialog(user.id)}
                        sx={{ bgcolor: '#f50057', '&:hover': { bgcolor: '#c51162' } }}
                      >
                        Добавить машину
                      </Button>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {['superadmin', 'owner', 'programmer'].includes(role) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenImportDialog(user.id)}
                        sx={{ bgcolor: '#f50057', '&:hover': { bgcolor: '#c51162' } }}
                      >
                        Импорт машин
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={openAddUserDialog} onClose={handleCloseAddUserDialog}>
        <DialogTitle sx={{ bgcolor: '#1976d2', color: 'white' }}>Создать пользователя</DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <TextField
            label="Телефон"
            fullWidth
            margin="normal"
            value={newUser.phone}
            onChange={(e) => setNewUser({ ...newUser, phone: e.target.value })}
            required
          />
          <TextField
            label="Пароль"
            type="password"
            fullWidth
            margin="normal"
            value={newUser.password}
            onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            required
          />
          <TextField
            label="Имя"
            fullWidth
            margin="normal"
            value={newUser.first_name}
            onChange={(e) => setNewUser({ ...newUser, first_name: e.target.value })}
          />
          <TextField
            label="Фамилия"
            fullWidth
            margin="normal"
            value={newUser.last_name}
            onChange={(e) => setNewUser({ ...newUser, last_name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddUserDialog} sx={{ color: '#757575' }}>Отмена</Button>
          <Button onClick={handleAddUser} variant="contained" color="primary">
            Создать
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddVehicleDialog} onClose={handleCloseAddVehicleDialog}>
        <DialogTitle sx={{ bgcolor: '#1976d2', color: 'white' }}>Добавить машину</DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <TextField
            label="Госномер"
            fullWidth
            margin="normal"
            value={newVehicle.license_plate}
            onChange={(e) => setNewVehicle({ ...newVehicle, license_plate: e.target.value })}
            required
          />
          <Select
            fullWidth
            displayEmpty
            value={newVehicle.client || ''}
            onChange={(e) => setNewVehicle({ ...newVehicle, client: e.target.value })}
            sx={{ mt: 2 }}
          >
            <MenuItem value="" disabled>
              Выберите клиента
            </MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddVehicleDialog} sx={{ color: '#757575' }}>Отмена</Button>
          <Button onClick={handleAddVehicle} variant="contained" color="primary">
            Добавить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openImportDialog} onClose={handleCloseImportDialog} fullWidth maxWidth="sm">
        <DialogTitle sx={{ bgcolor: '#1976d2', color: 'white' }}>Импорт автомобилей</DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Выберите Excel-файл с колонкой "Госномер". Каждый госномер будет добавлен для выбранного клиента.
          </Typography>
          <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
          {importing && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress variant="determinate" value={importProgress} />
              <Typography variant="body2" sx={{ mt: 1 }}>
                Загружено {Math.round((importProgress / 100) * importTotal)} из {importTotal}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImportDialog} sx={{ color: '#757575' }}>Отмена</Button>
          <Button onClick={handleImportVehicles} variant="contained" color="primary" disabled={importing}>
            Импортировать
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog} fullWidth maxWidth="sm">
        <DialogTitle sx={{ bgcolor: '#1976d2', color: 'white' }}>Редактировать пользователя</DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <TextField
            label="Имя"
            fullWidth
            margin="normal"
            value={editedFirstName}
            onChange={(e) => setEditedFirstName(e.target.value)}
          />
          <TextField
            label="Фамилия"
            fullWidth
            margin="normal"
            value={editedLastName}
            onChange={(e) => setEditedLastName(e.target.value)}
          />
          <TextField
            label="Компания"
            fullWidth
            margin="normal"
            value={editedCompany}
            onChange={(e) => setEditedCompany(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} sx={{ color: '#757575' }}>Отмена</Button>
          <Button onClick={handleEditUser} variant="contained" color="primary">
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Users;