// src/components/InspectionCards.js
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import Header from './Header';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(135deg, #ffffff, #f9f9f9)',
  overflow: 'hidden',
}));

const InspectionCards = () => {
  const { user } = useContext(UserContext);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [filterAuto, setFilterAuto] = useState('');
  const [filterPhone, setFilterPhone] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    column: 'date_of_validity',
    direction: 'asc',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const phone = localStorage.getItem('phone');
        if (!token) {
          navigate('/');
          return;
        }
        const role = user?.role || localStorage.getItem('role');
        setUserRole(role);

        const vehiclesResponse = await axios.get('http://77.221.151.92/api/all-vehicles/', {
          headers: { Authorization: `Token ${token}` },
        });
        let filteredVehicles = vehiclesResponse.data;

        if (role === 'manager') {
          const assignmentsResponse = await axios.get('http://77.221.151.92/api/manager-client-assignments/', {
            headers: { Authorization: `Token ${token}` },
          });
          const managerClients = assignmentsResponse.data
            .filter((assignment) => assignment.manager_phone === phone)
            .map((assignment) => assignment.client_phone);
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            managerClients.includes(vehicle.client_phone)
          );
        }

        setVehicles(filteredVehicles);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response?.status === 401) {
          navigate('/');
        } else if (error.response?.status === 403) {
          setError('Недостаточно прав для просмотра данных');
        } else {
          setError('Ошибка при загрузке данных');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate, user]);

  const filteredVehicles = useMemo(() => {
    return vehicles.filter((vehicle) => {
      const autoMatch =
        filterAuto === '' ||
        vehicle.license_plate?.toLowerCase().includes(filterAuto.toLowerCase()) ||
        vehicle.vin?.toLowerCase().includes(filterAuto.toLowerCase());
      const phoneMatch =
        filterPhone === '' ||
        vehicle.client_phone?.toLowerCase().includes(filterPhone.toLowerCase());

      let dateMatch = true;
      if (filterStartDate || filterEndDate) {
        const inspectionDate = vehicle.inspection?.date_of_validity;
        if (!inspectionDate) {
          dateMatch = false;
        } else {
          const validityDate = new Date(inspectionDate);
          if (filterStartDate && filterEndDate) {
            dateMatch =
              validityDate >= filterStartDate && validityDate <= filterEndDate;
          } else if (filterStartDate) {
            dateMatch = validityDate >= filterStartDate;
          } else if (filterEndDate) {
            dateMatch = validityDate <= filterEndDate;
          }
        }
      }

      return autoMatch && phoneMatch && dateMatch;
    });
  }, [vehicles, filterAuto, filterPhone, filterStartDate, filterEndDate]);

  const sortedVehicles = useMemo(() => {
    const sorted = [...filteredVehicles];
    if (sortConfig.column) {
      sorted.sort((a, b) => {
        let aValue, bValue;
        switch (sortConfig.column) {
          case 'date_of_diagnosis':
            aValue = a.inspection?.date_of_diagnosis
              ? new Date(a.inspection.date_of_diagnosis)
              : null;
            bValue = b.inspection?.date_of_diagnosis
              ? new Date(b.inspection.date_of_diagnosis)
              : null;
            break;
          case 'date_of_validity':
            aValue = a.inspection?.date_of_validity
              ? new Date(a.inspection.date_of_validity)
              : null;
            bValue = b.inspection?.date_of_validity
              ? new Date(b.inspection.date_of_validity)
              : null;
            break;
          default:
            return 0;
        }

        if (!aValue && !bValue) return 0;
        if (!aValue) return sortConfig.direction === 'asc' ? 1 : -1;
        if (!bValue) return sortConfig.direction === 'asc' ? -1 : 1;

        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      });
    }
    return sorted;
  }, [filteredVehicles, sortConfig]);

  const calculateDaysLeft = (endDate) => {
    if (!endDate) return '-';
    const today = new Date();
    const end = new Date(endDate);
    if (isNaN(end)) return '-';
    const diffTime = end - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays >= 0 ? diffDays : 'Истёк';
  };

  const formatDate = (dateStr) => {
    if (!dateStr) return '-';
    const d = new Date(dateStr);
    return isNaN(d) ? '-' : d.toLocaleDateString();
  };

  const handleSort = (column) => {
    setSortConfig((prev) => ({
      column,
      direction:
        prev.column === column && prev.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleNavigateToDashboard = () => {
    navigate('/dashboard');
  };

  const isPrivilegedRole = ['superadmin', 'owner', 'programmer'].includes(userRole);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Header title="Диагностические карты" />
        <Box sx={{ p: 3, maxWidth: '1400px', mx: 'auto' }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={userRole !== 'client' ? 4 : 6}>
              <TextField
                label="Поиск по госномеру / VIN"
                fullWidth
                value={filterAuto}
                onChange={(e) => setFilterAuto(e.target.value)}
                variant="outlined"
              />
            </Grid>
            {userRole !== 'client' && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Фильтр по номеру телефона клиента"
                  fullWidth
                  value={filterPhone}
                  onChange={(e) => setFilterPhone(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={6} sm={userRole !== 'client' ? 2 : 3}>
              <DatePicker
                label="Период с"
                value={filterStartDate}
                onChange={(newValue) => setFilterStartDate(newValue)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={userRole !== 'client' ? 2 : 3}>
              <DatePicker
                label="Период по"
                value={filterEndDate}
                onChange={(newValue) => setFilterEndDate(newValue)}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />
            </Grid>
          </Grid>

          <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNavigateToDashboard}
            >
              Пропуска
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled
              sx={{
                bgcolor: 'primary.light',
                '&:disabled': { bgcolor: 'primary.main', color: 'white' },
              }}
            >
              Диагностические карты
            </Button>
          </Box>

          <StyledTableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow sx={{ bgcolor: '#1976d2' }}>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                    Гос. номер
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                    Марка и модель
                  </TableCell>
                  {isPrivilegedRole && (
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                      Клиент
                    </TableCell>
                  )}
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                    Наличие
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                    Номер ЕАИСТО
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 1,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      cursor: 'pointer',
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('date_of_diagnosis')}
                  >
                    Дата диагностики
                    {sortConfig.column === 'date_of_diagnosis' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }}
                        />
                      )
                    ) : (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{
                          ml: 0.5,
                          verticalAlign: 'middle',
                          color: '#fff',
                          opacity: 0.5,
                          '&:hover': { opacity: 1 },
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 1,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      cursor: 'pointer',
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('date_of_validity')}
                  >
                    Действует до
                    {sortConfig.column === 'date_of_validity' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon
                          fontSize="small"
                          sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          fontSize="small"
                          sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }}
                        />
                      )
                    ) : (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{
                          ml: 0.5,
                          verticalAlign: 'middle',
                          color: '#fff',
                          opacity: 0.5,
                          '&:hover': { opacity: 1 },
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                    Осталось дней
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                    Номер оператора
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedVehicles.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={isPrivilegedRole ? 9 : 8} align="center">
                      <Typography fontSize="0.85rem" color="textSecondary">
                        Нет данных для отображения
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedVehicles.map((vehicle, index) => {
                    const daysLeft = calculateDaysLeft(vehicle.inspection?.date_of_validity);
                    const isExpiringSoon = typeof daysLeft === 'number' && daysLeft <= 20 && daysLeft >= 0;

                    return (
                      <TableRow
                        key={vehicle.id}
                        sx={{
                          bgcolor: isExpiringSoon
                            ? 'rgba(255, 82, 82, 0.2)'
                            : index % 2 === 0
                            ? '#FFFFFF'
                            : '#F9FAFB',
                          '&:hover': {
                            bgcolor: isExpiringSoon
                              ? 'rgba(255, 82, 82, 0.3)'
                              : '#E8F0FE',
                            transition: 'background-color 0.3s ease, transform 0.2s ease',
                            transform: 'scale(1.01)',
                          },
                        }}
                      >
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>
                          {vehicle.license_plate}
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>
                          {vehicle.brand} {vehicle.model}
                        </TableCell>
                        {isPrivilegedRole && (
                          <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>
                            {vehicle.client_phone || '-'}
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            fontSize: '0.85rem',
                            py: 0.8,
                            px: 1,
                            textAlign: 'center',
                            bgcolor: '#fff3cd',
                            '&:hover': { bgcolor: '#ffecb3' },
                          }}
                        >
                          {vehicle.inspection ? (
                            <CheckCircleIcon fontSize="small" color="success" />
                          ) : (
                            <CancelIcon fontSize="small" color="error" />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '0.85rem',
                            py: 0.8,
                            px: 1,
                            textAlign: 'center',
                            bgcolor: '#fff3cd',
                            '&:hover': { bgcolor: '#ffecb3' },
                          }}
                        >
                          {vehicle.inspection?.eaisto_number || '-'}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '0.85rem',
                            py: 0.8,
                            px: 1,
                            textAlign: 'center',
                            bgcolor: '#fff3cd',
                            '&:hover': { bgcolor: '#ffecb3' },
                          }}
                        >
                          {formatDate(vehicle.inspection?.date_of_diagnosis)}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '0.85rem',
                            py: 0.8,
                            px: 1,
                            textAlign: 'center',
                            bgcolor: '#fff3cd',
                            '&:hover': { bgcolor: '#ffecb3' },
                          }}
                        >
                          {formatDate(vehicle.inspection?.date_of_validity)}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '0.85rem',
                            py: 0.8,
                            px: 1,
                            textAlign: 'center',
                            bgcolor: '#fff3cd',
                            '&:hover': { bgcolor: '#ffecb3' },
                          }}
                        >
                          {daysLeft}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '0.85rem',
                            py: 0.8,
                            px: 1,
                            textAlign: 'center',
                            bgcolor: '#fff3cd',
                            '&:hover': { bgcolor: '#ffecb3' },
                          }}
                        >
                          {vehicle.inspection?.operator_number || '-'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default InspectionCards;