import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('http://77.221.151.92/api/user/', {
        headers: { Authorization: `Token ${token}` },
      });
      const userData = {
        phone: response.data.phone,
        role: response.data.role,
        first_name: response.data.first_name || '',
        last_name: response.data.last_name || '',
        company: response.data.company || '',
      };
      setUser(userData);
      localStorage.setItem('phone', userData.phone);
      localStorage.setItem('role', userData.role);
      localStorage.setItem('first_name', userData.first_name || '');
      localStorage.setItem('last_name', userData.last_name || '');
      localStorage.setItem('company', userData.company || '');
    } catch (error) {
      console.error('Error fetching user:', error);
      localStorage.removeItem('token');
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading, fetchUserData }}>
      {children}
    </UserContext.Provider>
  );
};