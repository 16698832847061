// src/components/ManagerAccess.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
} from '@mui/material';

const ManagerAccess = () => {
  const [managers, setManagers] = useState([]);
  const [clients, setClients] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [openAddManagerDialog, setOpenAddManagerDialog] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [newManager, setNewManager] = useState({ phone: '', first_name: '', last_name: '', password: '' });
  const [newAssignment, setNewAssignment] = useState({ manager: '', client: '' });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        if (!token) {
          navigate('/');
          return;
        }
        setUserRole(role);

        const managersResponse = await axios.get('http://77.221.151.92/api/all-managers/', {
          headers: { Authorization: `Token ${token}` },
        });
        setManagers(managersResponse.data);

        const clientsResponse = await axios.get('http://77.221.151.92/api/all-users/', {
          headers: { Authorization: `Token ${token}` },
        });
        setClients(clientsResponse.data);

        const assignmentsResponse = await axios.get('http://77.221.151.92/api/manager-client-assignments/', {
          headers: { Authorization: `Token ${token}` },
        });
        setAssignments(assignmentsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response?.status === 401) {
          navigate('/');
        } else if (error.response?.status === 403) {
          setError('Недостаточно прав для просмотра данных');
        } else {
          setError('Ошибка при загрузке данных');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleShowDashboard = () => {
    navigate('/dashboard');
  };

  const handleShowAllUsers = () => {
    navigate('/users');
  };

  const handleShowAllManagers = () => {
    navigate('/manager-access');
  };

  const handleOpenAddManagerDialog = () => {
    setOpenAddManagerDialog(true);
  };

  const handleCloseAddManagerDialog = () => {
    setOpenAddManagerDialog(false);
    setNewManager({ phone: '', first_name: '', last_name: '', password: '' });
  };

  const handleAddManager = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'http://77.221.151.92/api/add-user/',
        { ...newManager, role: 'manager' },
        { headers: { Authorization: `Token ${token}` } }
      );
      setManagers([...managers, response.data]);
      handleCloseAddManagerDialog();
      alert('Менеджер успешно добавлен');
    } catch (error) {
      console.error('Error adding manager:', error);
      setError(error.response?.data?.phone || 'Ошибка при добавлении менеджера');
    }
  };

  const handleOpenAssignDialog = () => {
    setOpenAssignDialog(true);
  };

  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
    setNewAssignment({ manager: '', client: '' });
  };

  const handleAssignManager = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        'http://77.221.151.92/api/manager-client-assignments/',
        newAssignment,
        { headers: { Authorization: `Token ${token}` } }
      );
      setAssignments([...assignments, response.data]);
      handleCloseAssignDialog();
      alert('Клиент успешно привязан к менеджеру');
    } catch (error) {
      console.error('Error assigning manager:', error);
      setError(error.response?.data?.error || 'Ошибка при привязке клиента');
    }
  };

  const handleDeleteAssignment = async (assignmentId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`http://77.221.151.92/api/delete-manager-client-assignment/${assignmentId}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setAssignments(assignments.filter((assignment) => assignment.id !== assignmentId));
      alert('Привязка успешно удалена');
    } catch (error) {
      console.error('Error deleting assignment:', error);
      setError('Ошибка при удалении привязки');
    }
  };

  const handleShowManagerClients = () => {
    const managerClients = assignments.filter((assignment) => assignment.manager_phone === localStorage.getItem('phone'));
    setClients(managerClients.map((assignment) => ({
      id: assignment.client,
      phone: assignment.client_phone,
    })));
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Header title="Настройка доступа менеджеров" />
      <Box sx={{ p: 3 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {['owner', 'programmer', 'superadmin'].includes(userRole) && (
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" color="primary" onClick={handleShowAllUsers} sx={{ mr: 2 }}>
              Показать всех пользователей
            </Button>
            <Button variant="contained" color="primary" onClick={handleShowAllManagers} sx={{ mr: 2 }}>
              Показать всех менеджеров
            </Button>
            <Button variant="contained" color="primary" onClick={handleOpenAddManagerDialog} sx={{ mr: 2 }}>
              Зарегистрировать менеджера
            </Button>
            <Button variant="contained" color="primary" onClick={handleOpenAssignDialog}>
              Привязать менеджера к клиенту
            </Button>
          </Box>
        )}

        {userRole === 'manager' && (
          <Box sx={{ mb: 2 }}>
            <Button variant="contained" color="primary" onClick={handleShowManagerClients}>
              Список клиентов
            </Button>
          </Box>
        )}

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Менеджер</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Клиент</TableCell>
                {['owner', 'programmer', 'superadmin'].includes(userRole) && (
                  <TableCell sx={{ fontWeight: 'bold' }}>Действия</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {assignments.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Нет привязок менеджеров к клиентам
                  </TableCell>
                </TableRow>
              ) : (
                assignments.map((assignment) => (
                  <TableRow key={assignment.id}>
                    <TableCell>{assignment.manager_phone}</TableCell>
                    <TableCell>{assignment.client_phone}</TableCell>
                    {['owner', 'programmer', 'superadmin'].includes(userRole) && (
                      <TableCell>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteAssignment(assignment.id)}
                        >
                          Удалить
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Dialog open={openAddManagerDialog} onClose={handleCloseAddManagerDialog}>
        <DialogTitle>Зарегистрировать менеджера</DialogTitle>
        <DialogContent>
          <TextField
            label="Телефон"
            fullWidth
            margin="normal"
            value={newManager.phone}
            onChange={(e) => setNewManager({ ...newManager, phone: e.target.value })}
            required
          />
          <TextField
            label="Имя"
            fullWidth
            margin="normal"
            value={newManager.first_name}
            onChange={(e) => setNewManager({ ...newManager, first_name: e.target.value })}
          />
          <TextField
            label="Фамилия"
            fullWidth
            margin="normal"
            value={newManager.last_name}
            onChange={(e) => setNewManager({ ...newManager, last_name: e.target.value })}
          />
          <TextField
            label="Пароль"
            fullWidth
            margin="normal"
            type="password"
            value={newManager.password}
            onChange={(e) => setNewManager({ ...newManager, password: e.target.value })}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddManagerDialog}>Отмена</Button>
          <Button onClick={handleAddManager} variant="contained" color="primary">
            Добавить
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAssignDialog} onClose={handleCloseAssignDialog}>
        <DialogTitle>Привязать менеджера к клиенту</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            displayEmpty
            value={newAssignment.manager}
            onChange={(e) => setNewAssignment({ ...newAssignment, manager: e.target.value })}
            sx={{ mt: 2 }}
          >
            <MenuItem value="" disabled>
              Выберите менеджера
            </MenuItem>
            {managers.map((manager) => (
              <MenuItem key={manager.id} value={manager.id}>
                {manager.phone}
              </MenuItem>
            ))}
          </Select>
          <Select
            fullWidth
            displayEmpty
            value={newAssignment.client}
            onChange={(e) => setNewAssignment({ ...newAssignment, client: e.target.value })}
            sx={{ mt: 2 }}
          >
            <MenuItem value="" disabled>
              Выберите клиента
            </MenuItem>
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>
                {client.phone}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAssignDialog}>Отмена</Button>
          <Button onClick={handleAssignManager} variant="contained" color="primary">
            Привязать
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManagerAccess;