// src/components/Dashboard.js
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import axios from 'axios';
import Header from './Header';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Alert,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { styled } from '@mui/system';

// Стили для "вау"-эффекта
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  background: 'linear-gradient(135deg, #ffffff, #f9f9f9)',
  overflow: 'hidden',
}));

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [vehicles, setVehicles] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [openAddVehicleDialog, setOpenAddVehicleDialog] = useState(false);
  const [openPassRequestDialog, setOpenPassRequestDialog] = useState(false);
  const [newVehicle, setNewVehicle] = useState({ license_plate: '' });
  const [passRequest, setPassRequest] = useState({ licensePlate: '', comment: '' });
  const [filterAuto, setFilterAuto] = useState('');
  const [filterPhone, setFilterPhone] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    column: 'start_date',
    direction: 'asc',
    passType: 'day',
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const phone = localStorage.getItem('phone');
        if (!token) {
          navigate('/');
          return;
        }
        const role = user?.role || localStorage.getItem('role');
        setUserRole(role);

        const vehiclesResponse = await axios.get('http://77.221.151.92/api/all-vehicles/', {
          headers: { Authorization: `Token ${token}` },
        });
        let filteredVehicles = vehiclesResponse.data;

        if (role === 'manager') {
          const assignmentsResponse = await axios.get('http://77.221.151.92/api/manager-client-assignments/', {
            headers: { Authorization: `Token ${token}` },
          });
          const managerClients = assignmentsResponse.data
            .filter((assignment) => assignment.manager_phone === phone)
            .map((assignment) => assignment.client_phone);
          filteredVehicles = filteredVehicles.filter((vehicle) =>
            managerClients.includes(vehicle.client_phone)
          );
        }

        setVehicles(filteredVehicles);

        if (['superadmin', 'owner', 'programmer', 'manager'].includes(role)) {
          const clientsResponse = await axios.get('http://77.221.151.92/api/all-users/', {
            headers: { Authorization: `Token ${token}` },
          });
          setClients(clientsResponse.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response?.status === 401) {
          navigate('/');
        } else if (error.response?.status === 403) {
          setError('Недостаточно прав для просмотра данных');
        } else {
          setError('Ошибка при загрузке данных');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate, user]);

  const getPassByType = (passes, type) => {
    if (!passes || passes.length === 0) return null;
    const filtered = passes.filter((p) => p.type === type);
    if (filtered.length === 0) return null;
    const validPass = filtered.find((p) => p.status === 'Действующий');
    return validPass || filtered[0];
  };

  const calculateDaysLeft = (endDate) => {
    if (!endDate) return '-';
    const today = new Date();
    const end = new Date(endDate);
    if (isNaN(end)) return '-';
    const diffTime = end - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays >= 0 ? diffDays : 'Истёк';
  };

  const filteredVehicles = useMemo(() => {
    return vehicles.filter((vehicle) => {
      const autoMatch =
        filterAuto === '' ||
        (vehicle.license_plate?.toLowerCase().includes(filterAuto.toLowerCase())) ||
        (vehicle.vin?.toLowerCase().includes(filterAuto.toLowerCase()));
      const phoneMatch =
        filterPhone === '' ||
        (vehicle.client_phone?.toLowerCase().includes(filterPhone.toLowerCase()));

      let dateMatch = true;
      if (filterStartDate || filterEndDate) {
        const dayPass = getPassByType(vehicle.passes, 'Дневной');
        const nightPass = getPassByType(vehicle.passes, 'Ночной');
        const relevantDate = dayPass?.end_date || nightPass?.end_date;

        if (!relevantDate) {
          dateMatch = false;
        } else {
          const endDateParsed = new Date(relevantDate);
          if (filterStartDate && filterEndDate) {
            dateMatch =
              endDateParsed >= filterStartDate && endDateParsed <= filterEndDate;
          } else if (filterStartDate) {
            dateMatch = endDateParsed >= filterStartDate;
          } else if (filterEndDate) {
            dateMatch = endDateParsed <= filterEndDate;
          }
        }
      }

      return autoMatch && phoneMatch && dateMatch;
    });
  }, [vehicles, filterAuto, filterPhone, filterStartDate, filterEndDate]);

  const sortedVehicles = useMemo(() => {
    const sorted = [...filteredVehicles];
    if (sortConfig.column) {
      sorted.sort((a, b) => {
        const passType = sortConfig.passType === 'day' ? 'Дневной' : 'Ночной';
        const aPass = getPassByType(a.passes, passType);
        const bPass = getPassByType(b.passes, passType);

        let aValue, bValue;
        switch (sortConfig.column) {
          case 'start_date':
            aValue = aPass?.start_date ? new Date(aPass.start_date) : null;
            bValue = bPass?.start_date ? new Date(bPass.start_date) : null;
            break;
          case 'end_date':
            aValue = aPass?.end_date ? new Date(aPass.end_date) : null;
            bValue = bPass?.end_date ? new Date(bPass.end_date) : null;
            break;
          case 'days_left':
            aValue = calculateDaysLeft(aPass?.end_date);
            bValue = calculateDaysLeft(bPass?.end_date);
            break;
          default:
            return 0;
        }

        if (!aValue && !bValue) return 0;
        if (!aValue) return sortConfig.direction === 'asc' ? 1 : -1;
        if (!bValue) return sortConfig.direction === 'asc' ? -1 : 1;

        if (sortConfig.column === 'days_left') {
          if (aValue === 'Истёк' && bValue !== 'Истёк') return sortConfig.direction === 'asc' ? -1 : 1;
          if (bValue === 'Истёк' && aValue !== 'Истёк') return sortConfig.direction === 'asc' ? 1 : -1;
          if (aValue === 'Истёк' && bValue === 'Истёк') return 0;
          return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }

        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      });
    }
    return sorted;
  }, [filteredVehicles, sortConfig]);

  const formatDate = (dateStr) => {
    if (!dateStr) return '-';
    const d = new Date(dateStr);
    return isNaN(d) ? '-' : d.toLocaleDateString();
  };

  const handleSort = (column, passType) => {
    setSortConfig((prev) => ({
      column,
      direction:
        prev.column === column && prev.passType === passType && prev.direction === 'asc' ? 'desc' : 'asc',
      passType,
    }));
  };

  const handleShowAllUsers = () => {
    navigate('/users');
  };

  const handleManagerAccessSettings = () => {
    navigate('/manager-access');
  };

  const handleOpenAddVehicleDialog = () => {
    setOpenAddVehicleDialog(true);
  };

  const handleCloseAddVehicleDialog = () => {
    setOpenAddVehicleDialog(false);
    setNewVehicle({ license_plate: '' });
  };

  const handleAddVehicle = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/');
        return;
      }
      if (!newVehicle.client || !newVehicle.license_plate) {
        setError('Укажите клиента и госномер');
        return;
      }

      const response = await axios.post(
        'http://77.221.151.92/api/add-vehicle-to-client/',
        { client_id: newVehicle.client, license_plate: newVehicle.license_plate },
        { headers: { Authorization: `Token ${token}` } }
      );
      setVehicles([...vehicles, response.data]);
      handleCloseAddVehicleDialog();
      alert('Автомобиль успешно добавлен');
    } catch (error) {
      console.error('Error adding vehicle:', error);
      setError(error.response?.data?.error || 'Ошибка при добавлении автомобиля');
    }
  };

  const handleDeleteVehicle = async (vehicleId) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот автомобиль?')) return;

    try {
      const token = localStorage.getItem('token');
      await axios.delete(`http://77.221.151.92/api/delete-vehicle/${vehicleId}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setVehicles(vehicles.filter((vehicle) => vehicle.id !== vehicleId));
      alert('Автомобиль успешно удалён');
    } catch (error) {
      console.error('Error deleting vehicle:', error);
      setError(error.response?.data?.error || 'Ошибка при удалении автомобиля');
    }
  };

  const handleOpenPassRequestDialog = () => {
    setOpenPassRequestDialog(true);
  };

  const handleClosePassRequestDialog = () => {
    setOpenPassRequestDialog(false);
    setPassRequest({ licensePlate: '', comment: '' });
  };

  const handlePassRequestSubmit = () => {
    alert('Данный функционал в разработке');
    handleClosePassRequestDialog();
  };

  const handleNavigateToInspectionCards = () => {
    navigate('/inspection-cards');
  };

  const isPrivilegedRole = ['superadmin', 'owner', 'programmer'].includes(userRole);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Header title="Личный кабинет" />
        <Box sx={{ p: 3, maxWidth: '1400px', mx: 'auto' }}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {userRole === 'client' && (
            <Card sx={{ mb: 3, boxShadow: 3, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6" color="text.primary" gutterBottom>
                  Ваш менеджер
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>Имя:</strong> Сергей Смирнов
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>Телефон:</strong> +7 (909) 955-93-33
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  <strong>Почта:</strong> sk@dok-pro.ru
                </Typography>
              </CardContent>
            </Card>
          )}

          {isPrivilegedRole && (
            <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
              {userRole === 'superadmin' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenAddVehicleDialog}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Добавить автомобиль
                </Button>
              )}
              <Button variant="contained" color="primary" onClick={handleShowAllUsers}>
                Показать всех пользователей
              </Button>
              <Button variant="contained" color="primary" onClick={handleManagerAccessSettings}>
                Настройка доступа менеджеров
              </Button>
            </Box>
          )}

          {userRole === 'client' && (
            <Box sx={{ mb: 3 }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleOpenPassRequestDialog}
                startIcon={<AddCircleOutlineIcon />}
                sx={{ boxShadow: 2 }}
              >
                Заказать пропуск
              </Button>
            </Box>
          )}

          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={userRole !== 'client' ? 4 : 6}>
              <TextField
                label="Поиск по госномеру / VIN"
                fullWidth
                value={filterAuto}
                onChange={(e) => setFilterAuto(e.target.value)}
                variant="outlined"
              />
            </Grid>
            {userRole !== 'client' && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Фильтр по номеру телефона клиента"
                  fullWidth
                  value={filterPhone}
                  onChange={(e) => setFilterPhone(e.target.value)}
                  variant="outlined"
                />
              </Grid>
            )}
            <Grid item xs={6} sm={userRole !== 'client' ? 2 : 3}>
              <DatePicker
                label="Период с"
                value={filterStartDate}
                onChange={(newValue) => setFilterStartDate(newValue)}
                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
              />
            </Grid>
            <Grid item xs={6} sm={userRole !== 'client' ? 2 : 3}>
              <DatePicker
                label="Период по"
                value={filterEndDate}
                onChange={(newValue) => setFilterEndDate(newValue)}
                renderInput={(params) => <TextField {...params} variant="outlined" fullWidth />}
              />
            </Grid>
          </Grid>

          <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disabled
              sx={{ bgcolor: 'primary.light', '&:disabled': { bgcolor: 'primary.main', color: 'white' } }}
            >
              Пропуска
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNavigateToInspectionCards}
            >
              Диагностические карты
            </Button>
          </Box>

          <StyledTableContainer component={Paper}>
            <Table size="small" sx={{ tableLayout: isPrivilegedRole ? 'auto' : 'fixed', width: '100%' }}>
              <TableHead>
                <TableRow sx={{ bgcolor: '#1976d2' }}>
                  <TableCell colSpan={isPrivilegedRole ? 4 : 3} sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center' }}>
                    Краткая информация о ТС
                  </TableCell>
                  <TableCell colSpan={5} sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center', bgcolor: '#1976d2' }}>
                    Дневной пропуск
                  </TableCell>
                  <TableCell colSpan={5} sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 1, px: 1, color: '#fff', textAlign: 'center', bgcolor: '#1976d2' }}>
                    Ночной пропуск
                  </TableCell>
                  {isPrivilegedRole && (
                    <TableCell rowSpan={2} sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', bgcolor: '#1976d2' }}>
                      Действия
                    </TableCell>
                  )}
                </TableRow>
                <TableRow sx={{ bgcolor: '#1976d2' }}>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', ...(isPrivilegedRole ? {} : { width: '10%' }) }}>
                    Гос. номер
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', ...(isPrivilegedRole ? {} : { width: '15%' }) }}>
                    Марка и модель
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', ...(isPrivilegedRole ? {} : { width: '10%' }) }}>
                    Диагн. карта
                  </TableCell>
                  {isPrivilegedRole && (
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center' }}>
                      Клиент
                    </TableCell>
                  )}
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', bgcolor: '#1976d2', ...(isPrivilegedRole ? {} : { width: '7%' }) }}>
                    Наличие
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', bgcolor: '#1976d2', ...(isPrivilegedRole ? {} : { width: '7%' }) }}>
                    Зона
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 0.8,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      bgcolor: '#1976d2',
                      cursor: 'pointer',
                      ...(isPrivilegedRole ? {} : { width: '10%' }),
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('start_date', 'day')}
                  >
                    Действует с
                    {sortConfig.column === 'start_date' && sortConfig.passType === 'day' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      )
                    ) : (
                      <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff', opacity: 0.5, '&:hover': { opacity: 1 } }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 0.8,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      bgcolor: '#1976d2',
                      cursor: 'pointer',
                      ...(isPrivilegedRole ? {} : { width: '10%' }),
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('end_date', 'day')}
                  >
                    Действует по
                    {sortConfig.column === 'end_date' && sortConfig.passType === 'day' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      )
                    ) : (
                      <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff', opacity: 0.5, '&:hover': { opacity: 1 } }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 0.8,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      bgcolor: '#1976d2',
                      cursor: 'pointer',
                      ...(isPrivilegedRole ? {} : { width: '8%' }),
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('days_left', 'day')}
                  >
                    Осталось дней
                    {sortConfig.column === 'days_left' && sortConfig.passType === 'day' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      )
                    ) : (
                      <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff', opacity: 0.5, '&:hover': { opacity: 1 } }} />
                    )}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', bgcolor: '#1976d2', ...(isPrivilegedRole ? {} : { width: '7%' }) }}>
                    Наличие
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '0.85rem', py: 0.8, px: 1, color: '#fff', textAlign: 'center', bgcolor: '#1976d2', ...(isPrivilegedRole ? {} : { width: '7%' }) }}>
                    Зона
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 0.8,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      bgcolor: '#1976d2',
                      cursor: 'pointer',
                      ...(isPrivilegedRole ? {} : { width: '10%' }),
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('start_date', 'night')}
                  >
                    Действует с
                    {sortConfig.column === 'start_date' && sortConfig.passType === 'night' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      )
                    ) : (
                      <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff', opacity: 0.5, '&:hover': { opacity: 1 } }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 0.8,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      bgcolor: '#1976d2',
                      cursor: 'pointer',
                      ...(isPrivilegedRole ? {} : { width: '10%' }),
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('end_date', 'night')}
                  >
                    Действует по
                    {sortConfig.column === 'end_date' && sortConfig.passType === 'night' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      )
                    ) : (
                      <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff', opacity: 0.5, '&:hover': { opacity: 1 } }} />
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '0.85rem',
                      py: 0.8,
                      px: 1,
                      color: '#fff',
                      textAlign: 'center',
                      bgcolor: '#1976d2',
                      cursor: 'pointer',
                      ...(isPrivilegedRole ? {} : { width: '8%' }),
                      '&:hover': { bgcolor: '#1565c0' },
                    }}
                    onClick={() => handleSort('days_left', 'night')}
                  >
                    Осталось дней
                    {sortConfig.column === 'days_left' && sortConfig.passType === 'night' ? (
                      sortConfig.direction === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff' }} />
                      )
                    ) : (
                      <ArrowUpwardIcon fontSize="small" sx={{ ml: 0.5, verticalAlign: 'middle', color: '#fff', opacity: 0.5, '&:hover': { opacity: 1 } }} />
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedVehicles.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={isPrivilegedRole ? 15 : 13} align="center">
                      <Typography fontSize="0.85rem" color="textSecondary">
                        Нет автомобилей для отображения
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedVehicles.map((vehicle, index) => {
                    const dayPass = getPassByType(vehicle.passes, 'Дневной');
                    const nightPass = getPassByType(vehicle.passes, 'Ночной');

                    return (
                      <TableRow
                        key={vehicle.id}
                        sx={{
                          bgcolor: index % 2 === 0 ? '#FFFFFF' : '#F9FAFB',
                          '&:hover': {
                            bgcolor: '#E8F0FE',
                            transition: 'background-color 0.3s ease, transform 0.2s ease',
                            transform: 'scale(1.01)',
                          },
                        }}
                      >
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>{vehicle.license_plate}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>
                          {vehicle.brand} {vehicle.model}
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>
                          {vehicle.inspection?.is_valid ? 'Да' : 'Нет'}
                        </TableCell>
                        {isPrivilegedRole && (
                          <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>{vehicle.client_phone || '-'}</TableCell>
                        )}
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#fff9c4', '&:hover': { bgcolor: '#ffecb3' } }}>
                          {dayPass ? (
                            <WbSunnyIcon fontSize="small" sx={{ color: 'success.main' }} />
                          ) : (
                            <WbSunnyIcon fontSize="small" sx={{ color: 'error.main' }} />
                          )}
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#fff9c4', '&:hover': { bgcolor: '#ffecb3' } }}>{dayPass?.allowed_zona || '-'}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#fff9c4', '&:hover': { bgcolor: '#ffecb3' } }}>{formatDate(dayPass?.start_date)}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#fff9c4', '&:hover': { bgcolor: '#ffecb3' } }}>{formatDate(dayPass?.end_date)}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#fff9c4', '&:hover': { bgcolor: '#ffecb3' } }}>{calculateDaysLeft(dayPass?.end_date)}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#e3f2fd', '&:hover': { bgcolor: '#bbdefb' } }}>
                          {nightPass ? (
                            <NightsStayIcon fontSize="small" sx={{ color: 'success.main' }} />
                          ) : (
                            <NightsStayIcon fontSize="small" sx={{ color: 'error.main' }} />
                          )}
                        </TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#e3f2fd', '&:hover': { bgcolor: '#bbdefb' } }}>{nightPass?.allowed_zona || '-'}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#e3f2fd', '&:hover': { bgcolor: '#bbdefb' } }}>{formatDate(nightPass?.start_date)}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#e3f2fd', '&:hover': { bgcolor: '#bbdefb' } }}>{formatDate(nightPass?.end_date)}</TableCell>
                        <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center', bgcolor: '#e3f2fd', '&:hover': { bgcolor: '#bbdefb' } }}>{calculateDaysLeft(nightPass?.end_date)}</TableCell>
                        {isPrivilegedRole && (
                          <TableCell sx={{ fontSize: '0.85rem', py: 0.8, px: 1, textAlign: 'center' }}>
                            <Button
                              variant="outlined"
                              color="error"
                              size="small"
                              startIcon={<DeleteIcon />}
                              onClick={() => handleDeleteVehicle(vehicle.id)}
                            >
                              Удалить
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </StyledTableContainer>

          <>
            <Dialog open={openAddVehicleDialog} onClose={handleCloseAddVehicleDialog} fullWidth maxWidth="sm">
              <DialogTitle>Добавить машину</DialogTitle>
              <DialogContent>
                <TextField
                  label="Госномер"
                  fullWidth
                  margin="normal"
                  value={newVehicle.license_plate}
                  onChange={(e) => setNewVehicle({ ...newVehicle, license_plate: e.target.value })}
                  required
                  variant="outlined"
                />
                <Select
                  fullWidth
                  displayEmpty
                  value={newVehicle.client || ''}
                  onChange={(e) => setNewVehicle({ ...newVehicle, client: e.target.value })}
                  sx={{ mt: 2 }}
                  variant="outlined"
                >
                  <MenuItem value="" disabled>
                    Выберите клиента
                  </MenuItem>
                  {clients.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.phone}
                    </MenuItem>
                  ))}
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddVehicleDialog}>Отмена</Button>
                <Button onClick={handleAddVehicle} variant="contained" color="primary">
                  Добавить
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openPassRequestDialog} onClose={handleClosePassRequestDialog} fullWidth maxWidth="sm">
              <DialogTitle sx={{ bgcolor: '#1976d2', color: 'white', textAlign: 'center', py: 2, borderBottom: '1px solid #1565c0' }}>
                Заявка
              </DialogTitle>
              <DialogContent sx={{ py: 4, bgcolor: '#f5f5f5' }}>
                <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mb: 3, mt: 1 }}>
                  Для оформления пропуска заполните форму ниже
                </Typography>
                <Box
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    bgcolor: 'white',
                    p: 3,
                    borderRadius: 2,
                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  }}
                >
                  <TextField
                    label="Госномер"
                    fullWidth
                    value={passRequest.licensePlate}
                    onChange={(e) => setPassRequest({ ...passRequest, licensePlate: e.target.value })}
                    required
                    variant="outlined"
                    placeholder="Введите госномер автомобиля"
                    sx={{ '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                  />
                  <Select
                    fullWidth
                    value={passRequest.series || ''}
                    onChange={(e) => setPassRequest({ ...passRequest, series: e.target.value })}
                    displayEmpty
                    variant="outlined"
                    sx={{ borderRadius: '8px' }}
                    renderValue={(selected) => (selected ? selected : <em>Выберите серию пропуска</em>)}
                  >
                    <MenuItem value="" disabled>
                      Выберите серию пропуска
                    </MenuItem>
                    <MenuItem value="Временный пропуск">Временный пропуск</MenuItem>
                    <MenuItem value="Годовой пропуск">Годовой пропуск</MenuItem>
                  </Select>
                  <Select
                    fullWidth
                    value={passRequest.timeOfDay || ''}
                    onChange={(e) => setPassRequest({ ...passRequest, timeOfDay: e.target.value })}
                    displayEmpty
                    variant="outlined"
                    sx={{ borderRadius: '8px' }}
                    renderValue={(selected) => (selected ? selected : <em>Выберите время суток</em>)}
                  >
                    <MenuItem value="" disabled>
                      Выберите время суток
                    </MenuItem>
                    <MenuItem value="Дневной пропуск">Дневной пропуск</MenuItem>
                    <MenuItem value="Ночной пропуск">Ночной пропуск</MenuItem>
                  </Select>
                  <Select
                    fullWidth
                    value={passRequest.restrictionZone || ''}
                    onChange={(e) => setPassRequest({ ...passRequest, restrictionZone: e.target.value })}
                    displayEmpty
                    variant="outlined"
                    sx={{ borderRadius: '8px' }}
                    renderValue={(selected) => (selected ? selected : <em>Выберите зону ограничения</em>)}
                  >
                    <MenuItem value="" disabled>
                      Выберите зону ограничения
                    </MenuItem>
                    <MenuItem value="МКАД">МКАД</MenuItem>
                    <MenuItem value="ТТК">ТТК</MenuItem>
                    <MenuItem value="Садовое кольцо">Садовое кольцо</MenuItem>
                  </Select>
                  <DatePicker
                    label="Дата начала действия"
                    value={passRequest.startDate || null}
                    onChange={(newValue) => setPassRequest({ ...passRequest, startDate: newValue })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        sx={{ '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
                      />
                    )}
                    minDate={new Date()}
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between', px: 3, pb: 2 }}>
                <Button onClick={handleClosePassRequestDialog} color="secondary" sx={{ fontWeight: 'bold' }}>
                  Отмена
                </Button>
                <Button
                  onClick={handlePassRequestSubmit}
                  variant="contained"
                  color="primary"
                  disabled={!passRequest.licensePlate || !passRequest.series || !passRequest.timeOfDay || !passRequest.restrictionZone || !passRequest.startDate}
                  sx={{ px: 4, fontWeight: 'bold', borderRadius: '8px' }}
                >
                  Отправить
                </Button>
              </DialogActions>
            </Dialog>
          </>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default Dashboard;