import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../context/UserContext';
import { Box, TextField, Button, Typography, Alert } from '@mui/material';

const Login = () => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { setUser, fetchUserData } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Шаг 1: Получаем токен
      const response = await axios.post('http://77.221.151.92/api/token/', {
        phone,
        password,
      });
      const token = response.data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('role', response.data.role);
      localStorage.setItem('phone', response.data.phone);

      // Шаг 2: Используем fetchUserData из UserContext для получения и установки данных
      await fetchUserData(token);

      navigate('/dashboard');
    } catch (err) {
      setError('Неверный телефон или пароль');
      console.error('Login error:', err);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', mt: 8, p: 3, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h5" gutterBottom>
        Вход
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <form onSubmit={handleLogin}>
        <TextField
          label="Телефон"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="Пароль"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Войти
        </Button>
      </form>
    </Box>
  );
};

export default Login;